<template>
	<!-- splash start -->
	<section class="spl-sec">
		<img class="spl-img" width="108" height="108" src="@/assets/img/jblogo.svg" alt="logo">
	</section>
	<!-- splash end -->
  <router-view>
  </router-view>
 <!-- footer start -->

    <footer class="footer-tab">
        <div class="container">
            <div class="row pt-18">
                <div class="col d-flex align-items-center justify-content-center">
                  <router-link to="/" active-class="active">
                   
                      <img class="img-active first" src="./assets/img/icons/home-active.svg" alt="">
                      <img class="img-deactive " src="./assets/img/icons/home.svg" alt="">

                   
                    </router-link>
                </div>
                <div class="col d-flex align-items-center justify-content-center">
                    <router-link to="/portfolio" active-class="active">
                      <img class="img-active" src="./assets/img/icons/portfolio-active.svg" alt="">
                      <img class="img-deactive" src="./assets/img/icons/portfolio.svg" alt="">
                   
                    </router-link>
                </div>
                <div class="col d-flex align-items-center justify-content-center">
                   <router-link to="/product-reports" active-class="active">
                      <img class="img-active" src="./assets/img/icons/graph-active.svg" alt="">
                      <img class="img-deactive" src="./assets/img/icons/graph.svg" alt="">
                   
                    </router-link>
                </div>
                <div class="col d-flex align-items-center justify-content-center">
                    <router-link to="/notifications" active-class="active">
                      <img class="img-active" src="./assets/img/icons/noti-active.svg" alt="">
                      <img class="img-deactive" src="./assets/img/icons/noti.svg" alt="">
                   
                    </router-link>
                </div>
            </div>
        </div>
    </footer>
    <!-- footer end -->

</template>

<script>
// import HeaderLayout from './components/layout/Header.vue'
// import FooterLayout from './components/layout/Footer.vue'
// import HomePage from './components/Home.vue'
	import $ from 'jquery'

export default {
  name: 'App',
			mounted:function(){
					this.splash()
			},
			methods:{
				splash() {
						setTimeout(function(){
						$('.spl-sec').fadeOut("slow");
						},1000);
				}
				},
}


</script>



