<template>
     <!-- back section start -->
        <div class="container mt-3 mb-4 ">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <p class="me-3 font-20 text-color-6 ">Transactions</p>
                    <p class="mb-0 font-20 fw-normal  text-color"> Cashflow</p>
                </div>
                <span class="cross-btn">+</span>
            </div>
        </div>
         <section class="mb-3">
            <div class="">
                <div class="bg-6 pt-2 pb-2">
                    <div class="container">
                        <p class="font-12 text-color-7 pb-1 pt-1 d-flex align-items-center justify-content-between">
                            <span class="text-color-7 font-14">Current Balance</span><span
                                class="font-20 text-dark">13,53,404.87</span></p>
                    </div>
                </div>
                <div class="container pt-3 pb-3 bg-white ">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="font-14 text-color-7">Provisional Balance</p>
                        <select class="form-select w-auto theme-select-box font-11 fw-300 rounded-pill bg-5"
                            aria-label="Default select example">
                            <option selected>Next Week</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>
                <div class="box-line">
                    <div class="d-flex align-items-center justify-content-between box-line-inner">
                        <div class="">
                        <p class="font-14 text-dark fw-300">29 Sep 2021</p>
                    </div>
                    <div class="">
                        <h5 class="font-20 text-dark fw-300">13,53,404.87</h5>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between box-line-inner">
                        <div class="">
                        <p class="font-14 text-dark fw-300">29 Sep 2021</p>
                        <p class="text-color-5 font-12">Stock purchase on 28 Sep 2021</p>
                        <p class="text-color-9 font-12">(- 22,93,417.67)</p>
                    </div>
                    <div class="">
                        <h5 class="font-20 text-dark fw-300">13,53,404.87</h5>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between box-line-inner">
                        <div class="">
                        <p class="font-14 text-dark fw-300">29 Sep 2021</p>
                        <p class="text-color-5 font-12">Stock purchase on 28 Sep 2021</p>
                        <p class="text-color-9 font-12">(- 22,93,417.67)</p>
                    </div>
                    <div class="">
                        <h5 class="font-20 text-dark fw-300">13,53,404.87</h5>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between box-line-inner">
                        <div class="">
                        <p class="font-14 text-dark fw-300">29 Sep 2021</p>
                       
                    </div>
                    <div class="">
                        <h5 class="font-20 text-dark fw-300">13,53,404.87</h5>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between box-line-inner">
                        <div class="">
                        <p class="font-14 text-dark fw-300">29 Sep 2021</p>
                        <p class="text-color-5 font-12">Mutual fund redemption ICICI Pru Bluechip 95000 units in the account 1245</p>
                        <p class="text-color-10 font-12">(- 22,93,417.67)</p>
                    </div>
                    <div class="">
                        <h5 class="font-20 text-dark fw-300">13,53,404.87</h5>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between box-line-inner">
                        <div class="">
                        <p class="font-14 text-dark fw-300">29 Sep 2021</p>
                       
                    </div>
                    <div class="">
                        <h5 class="font-20 text-dark fw-300">13,53,404.87</h5>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between box-line-inner">
                        <div class="">
                        <p class="font-14 text-color fw-300">Logical Balance</p>
                    </div>
                    <div class="">
                        <h5 class="font-20 text-dark fw-300">13,53,404.87</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>




<script>
export default ({
    name: 'CashFlowPage'
})
</script>