<template>
<!-- welcome header end -->

  <!-- welcome header start -->

	<div class="container mt-4 mb-4">
		<div class="row">
			<div class="col-12">
				<div class="span-pill">
					<img src="../assets/img/icons/family.svg">
					Family Aggregate
				</div>
			</div>
			
		</div>
	</div>

<!-- welcome header end -->

<section class="p-3 gradiant-bg">
    <div class="d-flex justify-content-end align-items-center">
        <span class="d-block height-24 rounded me-3 bg-1 text-center"><img src="../assets/img/icons/union.svg" alt="" /></span>
        <span class="d-block height-44 text-center bg-theme rounded-circle"><img src="../assets/img/icons/transactions.svg" alt="" /></span>
    </div>
    <div class="">
        <p class="text-color-4 font-weight-600 letter-sp-1 text-uppercase font-14 text-center">Portfolio Value
        </p>
        <h2 class="text-color text-center font-44 fw-bolder">95.79 <span class="text-color-1">Cr</span></h2>
        <p class="text-dark font-14 text-center letter-sp-1 mt-3">Invested Corpus : <span class="text-color letter-sp-02 text-center font-black">81.34 <span class="text-color-1">Cr</span></span></p>
    </div>

</section>
<section class="mt-3">
    <div class="row ">

        <div class="col-5 me-2 ms-3 pe-4 text-end border-end">
            <p class="font-16 text-color-2 ">+4.33 Cr</p>
            <p class="font-12 text-color-3 ">Gain / Loss</p>
        </div>
        <div class="col-6 ">
            <p class="font-16 text-color-2">21.1% <span class="text-dark font-12">(BM : 18.32%)</span></p>
            <p class="font-12 text-color-3">XIRR - FY22</p>
        </div>

    </div>
</section>
	<div class="container">
			<div class="row">
				<div class="col-12">
					<ul class="nav nav-pills justify-content-evenly align-items-center mb-3" id="pills-tab"
						role="tablist">
						<li class="nav-item" role="presentation">
							<button
								class="  button-second  font-10 rounded-pill ps-3 pt-1 pb-1 pe-3  text-uppercase nav-link active"
								id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button"
								role="tab" aria-controls="pills-home" aria-selected="true">EQUITY</button>
						</li>
						<li class="nav-item" role="presentation">
							<button
								class="nav-link font-10 rounded-pill ps-3 pt-1 pb-1 pe-3  text-uppercase button-second"
								id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
								type="button" role="tab" aria-controls="pills-profile"
								aria-selected="false">DEBT</button>
						</li>
						<li class="nav-item" role="presentation">
							<button
								class="nav-link nav-link font-10 rounded-pill ps-3 pt-1 pb-1 pe-3  text-uppercase button-second"
								id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
								type="button" role="tab" aria-controls="pills-contact"
								aria-selected="false">CASH</button>
						</li>
						<li class="nav-item" role="presentation">
							<button
								class="nav-link nav-link font-10 rounded-pill ps-3 pt-1 pb-1 pe-3  text-uppercase button-second"
								id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-alternate"
								type="button" role="tab" aria-controls="pills-alternate"
								aria-selected="false">ALTERNATE</button>
						</li>
					</ul>
					<div class="tab-content" id="pills-tabContent">
						<div class="tab-pane fade show active" id="pills-home" role="tabpanel"
							aria-labelledby="pills-home-tab">
							<div class="tab-box-cl-1 rounded-12px">
								<div
									class="d-flex position-right justify-content-center align-items-center mt-0 equ-all">

									<h5 class="letter-sp-1 fw-normal">Equity</h5>
									<span class="d-block right-icon height-32 text-center box-cl rounded-circle"><img
											src="../assets/img/icons/angle-right.svg" alt="" /></span>
								</div>
								<div class="">

									<h3 class="text-dark text-center font-32">95.79 <span class="text-color-5">Cr</span>
									</h3>
									<p class="text-color-3  text-uppercase font-14 text-center">Market Value
									</p>
									<p class="text-dark font-14 text-center letter-sp-1 mt-2">Invested Corpus : <span
											class="text-color letter-sp-02 text-center font-black">81.34 <span
												class="text-color-1">Cr</span></span></p>
								</div>
								<div class="p-3">
									<div class="row m-0  border-top mt-2 pt-2">
										<div class="col-6 p-2 text-center  border-end   ">
											<p class="font-16 text-color-2 ">+4.57 Cr</p>
											<p class="font-12 text-color-3 ">Unrealised Gain/Loss</p>
										</div>
										<div class="col-6 p-2 text-center">
											<p class="font-16 text-color-2">+18.76%</p>
											<p class="font-12 text-color-3">XIRR</p>
										</div>

									</div>
								</div>
								<div class="row even-odd-color m-0">
									<div class="col-4 even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											Direct
											Equity <span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											Equity
											MF <span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											F&O<span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											PMS
											<span><img src="../assets/img/icons/angle-right.svg" /></span>
										</p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											AIF
											<span><img src="../assets/img/icons/angle-right.svg" /></span>
										</p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
								</div>

							</div>
						</div>
						<div class="tab-pane fade" id="pills-profile" role="tabpanel"
							aria-labelledby="pills-profile-tab">
							<div class="tab-box-cl-2 rounded-12px">
								<div
									class="d-flex position-right justify-content-center align-items-center mt-0 equ-all">

									<h5 class="letter-sp-1 fw-normal text-uppercase">Debt</h5>
									<span class="d-block right-icon height-32 text-center box-cl rounded-circle"><img
											src="../assets/img/icons/angle-right.svg" alt="" /></span>
								</div>
								<div class="">

									<h3 class="text-dark text-center font-32">95.79 <span class="text-color-5">Cr</span>
									</h3>
									<p class="text-color-3  text-uppercase font-14 text-center">Market Value
									</p>
									<p class="text-dark font-14 text-center letter-sp-1 mt-2">Invested Corpus : <span
											class="text-color letter-sp-02 text-center font-black">81.34 <span
												class="text-color-1">Cr</span></span></p>
								</div>
								<div class="p-3">
									<div class="row m-0  border-top mt-2 pt-2">
										<div class="col-6 p-2 text-center  border-end   ">
											<p class="font-16 text-color-2 ">+4.57 Cr</p>
											<p class="font-12 text-color-3 ">Unrealised Gain/Loss</p>
										</div>
										<div class="col-6 p-2 text-center">
											<p class="font-16 text-color-2">+18.76%</p>
											<p class="font-12 text-color-3">XIRR</p>
										</div>

									</div>
								</div>
								<div class="row even-odd-color m-0">
									<div class="col-4 even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											Direct
											Equity <span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											Equity
											MF <span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											F&O<span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											PMS
											<span><img src="../assets/img/icons/angle-right.svg" /></span>
										</p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											AIF
											<span><img src="../assets/img/icons/angle-right.svg" /></span>
										</p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
								</div>

							</div>
						</div>
						<div class="tab-pane fade" id="pills-contact" role="tabpanel"
							aria-labelledby="pills-contact-tab">
							<div class="tab-box-cl-3 rounded-12px">
								<div
									class="d-flex position-right justify-content-center align-items-center mt-0 equ-all">

									<h5 class="letter-sp-1 fw-normal text-uppercase">Cash & Cash Equivalents</h5>
									<span class="d-block right-icon height-32 text-center box-cl rounded-circle"><img
											src="../assets/img/icons/angle-right.svg" alt="" /></span>
								</div>
								<div class="">

									<h3 class="text-dark text-center font-32">95.79 <span class="text-color-5">Cr</span>
									</h3>
									<p class="text-color-3  text-uppercase font-14 text-center">Market Value
									</p>
									<p class="text-dark font-14 text-center letter-sp-1 mt-2">Invested Corpus : <span
											class="text-color letter-sp-02 text-center font-black">81.34 <span
												class="text-color-1">Cr</span></span></p>
								</div>
								<div class="p-3">
									<div class="row m-0  border-top mt-2 pt-2">
										<div class="col-6 p-2 text-center  border-end   ">
											<p class="font-16 text-color-2 ">+4.57 Cr</p>
											<p class="font-12 text-color-3 ">Unrealised Gain/Loss</p>
										</div>
										<div class="col-6 p-2 text-center">
											<p class="font-16 text-color-2">+18.76%</p>
											<p class="font-12 text-color-3">XIRR</p>
										</div>

									</div>
								</div>
								<div class="row even-odd-color m-0">
									<div class="col-4 even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											Direct
											Equity <span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											Equity
											MF <span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											F&O<span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											PMS
											<span><img src="../assets/img/icons/angle-right.svg" /></span>
										</p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											AIF
											<span><img src="../assets/img/icons/angle-right.svg" /></span>
										</p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
								</div>

							</div>
						</div>
						<div class="tab-pane fade" id="pills-alternate" role="tabpanel"
							aria-labelledby="pills-alternate-tab">
							<div class="tab-box-cl-4 rounded-12px">
								<div
									class="d-flex position-right justify-content-center align-items-center mt-0 equ-all">

									<h5 class="letter-sp-1 fw-normal text-uppercase">Alternate Investments</h5>
									<span class="d-block right-icon height-32 text-center box-cl rounded-circle"><img
											src="../assets/img/icons/angle-right.svg" alt="" /></span>
								</div>
								<div class="">

									<h3 class="text-dark text-center font-32">95.79 <span class="text-color-5">Cr</span>
									</h3>
									<p class="text-color-3  text-uppercase font-14 text-center">Market Value
									</p>
									<p class="text-dark font-14 text-center letter-sp-1 mt-2">Invested Corpus : <span
											class="text-color letter-sp-02 text-center font-black">81.34 <span
												class="text-color-1">Cr</span></span></p>
								</div>
								<div class="p-3">
									<div class="row m-0  border-top mt-2 pt-2">
										<div class="col-6 p-2 text-center  border-end   ">
											<p class="font-16 text-color-2 ">+4.57 Cr</p>
											<p class="font-12 text-color-3 ">Unrealised Gain/Loss</p>
										</div>
										<div class="col-6 p-2 text-center">
											<p class="font-16 text-color-2">+18.76%</p>
											<p class="font-12 text-color-3">XIRR</p>
										</div>

									</div>
								</div>
								<div class="row even-odd-color m-0">
									<div class="col-4 even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											Direct
											Equity <span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											Equity
											MF <span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											F&O<span><img src="../assets/img/icons/angle-right.svg" /></span></p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											PMS
											<span><img src="../assets/img/icons/angle-right.svg" /></span>
										</p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
									<div class="col-4  even-odd ps-3 pe-3 pt-2 pb-2">
										<p class="font-14 text-dark ">3.57 Cr</p>
										<p
											class="font-10 text-color-3 d-flex justify-content-between align-item-center">
											AIF
											<span><img src="../assets/img/icons/angle-right.svg" /></span>
										</p>
										<p class="font-12 text-color-3 ">(46)</p>

									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="equ-all">
                <div class="text-center mt-0 mb-3">

                    <h5 class="letter-sp-1 0 ">LOAN Summary</h5>

                </div>
                <div class="">

                    <h3 class="text-dark text-center font-32">95.79 <span class="text-color-5">Cr</span></h3>
                    <p class="text-color-3   text-uppercase font-12 mt-1 text-center">Outstanding Amount
                    </p>
                    <p class="text-dark font-14 text-center letter-sp-1 mt-2">Invested Corpus : <span class="text-color letter-sp-02 text-center font-black">81.34 <span class="text-color-1">Cr</span></span></p>
                </div>
                <div class="mt-2">
                    <div class="row m-0">
                        <div class="col-6 p-2 text-center  border-end   ">
                            <p class="font-16 text-color-2 ">15 <span class="text-color-5">L</span></p>
                            <p class="font-12 text-color-3 ">Interest Due</p>
                        </div>
                        <div class="col-6 p-2 text-center">
                            <p class="font-16 text-color-2 ">3 <span class="text-color-5">Cr</span></p>
                            <p class="font-12 text-color-3 ">Drawing Power</p>
                        </div>

                    </div>
                    <a href="#" class="text-decoration-none text-uppercase font-weight-600 mt-3 d-block text-center text-color font-12 font-VerlagCompressed">Know
                        More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="text-box">
                <div class="text-center mt-0 mb-3">
                    <h5 class="letter-sp-1 0  text-uppercase font-14 text-color">Percentage Exposure
                    </h5>
                </div>
                <div class="table-responsive scroll-bottom margin-mun-16">
                    <table class="table table-css table-borderless mb-0 ">
                        <thead class="border-0 ">
                            <tr>

                                <th class="fwl-light color-light" scope="col">Fund/PMS House</th>
                                <th class="fwl-light color-light" scope="col">Current Value</th>
                                <th class="fwl-light color-light" scope="col">% of Group
                                    Portfolio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td> HDFC</td>
                                <td>30.18 Cr</td>
                                <td>15.5%</td>

                            </tr>
                            <tr>

                                <td> HDFC</td>
                                <td>30.18 Cr</td>
                                <td>15.5%</td>

                            </tr>
                            <tr>

                                <td> HDFC</td>
                                <td>30.18 Cr</td>
                                <td>15.5%</td>

                            </tr>

                        </tbody>
                    </table>
                </div>
                <a href="#" class="text-decoration-none text-uppercase font-weight-600 mt-3 d-block text-center text-color font-12 font-VerlagCompressed">Show
                    More <span><img class="angle-down" src="../assets/img/icons/angle-right.svg" /></span></a>
            </div>
        </div>
    </div>
</div>

<!-- Equity Allocation section start -->

<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="text-box">
                <div class="text-center mt-0 mb-3">
                    <h5 class="letter-sp-1 0  text-uppercase font-14 text-color">Allocation</h5>
                </div>
                <div class="row mt-32">
                    <div class="col-8">
                        <div class="false-For-Bottom-Text">
                            <div class="mypiechart">
                                <canvas id="myCanvas" width="171" height="171"></canvas>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <ul class="pie-list">
                            <li class="mb-2"> Equity
                                <p class="mt-0">3.5 Cr</p>
                            </li>
                            <li class="mb-2"> Debt
                                <p class="mt-0">6.50 Cr</p>
                            </li>
                            <li class="mb-2"> Cash
                                <p class="mt-0">6 Cr</p>
                            </li>
                            <li class="mb-2"> Alternate
                                <p class="mt-0">2.57 Cr</p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Equity Allocation section end -->

<!-- returns section strart -->
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="text-box">
                <div class="text-center mt-0 mb-3">
                    <h5 class="letter-sp-1 0  text-uppercase font-14 text-color"> Returns
                    </h5>
                </div>
                <div class="table-responsive scroll-bottom margin-mun-16">
                    <table class="table table-css table-borderless mb-0 ">
                        <thead class="border-0 ">
                            <tr>

                                <th scope="col"></th>
                                <th scope="col">Gain /Loss</th>
                                <th scope="col">XIRR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>Equity</td>
                                <td class="text-color-2">30.18 Cr</td>
                                <td class="text-color-2">15.5%</td>

                            </tr>
                            <tr>

                                <td>Debt</td>
                                <td class="text-color-2">30.18 Cr</td>
                                <td class="text-color-2">15.5%</td>

                            </tr>
                            <tr>

                            <td>Cash</td>
                            <td class="text-color-2">30.18 Cr</td>
                            <td class="text-color-2">15.5%</td>

                            </tr>
                            <tr>

                                <td>Alternate</td>
                                <td class="text-color-2">30.18 Cr</td>
                                <td class="text-color-2">15.5%</td>

                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="row mt-3">
                    <div class="col-7">
                        <p class="mb-0">
                            <span class="dark-txt d-block">7.7%</span>
                            <span class=" text-color-3">Internationally Listed</span>
                        </p>
                    </div>
                    <div class="col-5">
                        <p class="mb-0 text-end">
                            <span class="dark-txt d-block">92.3%</span>
                            <span class="text-color-3">Locally Listed</span>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="aggr-graph progress mt-8">
                            <div class="aggr-line" role="progressbar" style="width: 15%;background: #434B77;" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="aggr-line" role="progressbar" style="width: 85%;background: #141E55;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- returns section end -->

<!-- classifications section strart -->
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="text-box">
                <div class="text-center mt-0 mb-3">
                    <h5 class="letter-sp-1 0  text-uppercase font-14 text-color"> Classification
                    </h5>
                </div>
                <div class="table-responsive scroll-bottom margin-mun-16">
                    <table class="table table-css table-borderless mb-0 ">
                        <thead class="border-0 ">
                            <tr>

                                <th scope="col">Investment Category/
                                    Scheme</th>
                                <th scope="col">Holding</th>
                                <th scope="col">Allocation</th>
                                <th scope="col">XIRR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td> Cash & Cash Equi-
                                    valents</td>
                                <td>30.18 Cr</td>
                                <td>15.5%</td>
                                <td>15.5%</td>

                            </tr>
                            <tr>

                                <td> HDFC</td>
                                <td>30.18 Cr</td>
                                <td>15.5%</td>
                                <td>15.5%</td>

                            </tr>
                            <tr class="tr-disabled">

                                <td> HDFC</td>
                                <td>30.18 Cr</td>
                                <td>15.5%</td>
                                <td>15.5%</td>

                            </tr>

                        </tbody>
                    </table>
                </div>
                <a href="#" class="text-decoration-none text-uppercase font-weight-600 mt-3 d-block text-center text-color font-12 font-VerlagCompressed">Show
                    More <span><img class="angle-down" src="../assets/img/icons/angle-right.svg" /></span></a>
            </div>
        </div>
    </div>
</div>
<!-- classifications section end -->
</template>

<script>
export default ({
    name: 'PortfolioPage'
})
</script>
