<template>
   <!-- back section start -->
        <div class="container mt-3 mb-4">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <span class="me-3 d-block"><img src="../assets/img/icons/left-angle-long.svg" alt="" /></span>
                    <p class="mb-0 font-20 text-dark">Direct Equity (46)</p>
                </div>
                <div class="d-flex align-items-center">
                    <p class="text-color text-center font-20 fw-300 me-3">95.79 <span class="text-color-1">Cr</span></p>
                    <span data-bs-toggle="modal" data-bs-target="#exampleModal"
                        class="d-block height-24 rounded me-3 bg-5 text-center"><img src="../assets/img/icons/down-bar.svg"
                            alt="" /></span>
                </div>

            </div>
        </div>
          <section class="mb-3">
            <div class="">

                <div class="container pt-3 pb-3 bg-white ">
                    <div class="d-flex justify-content-end align-items-center">

                        <select
                            class="form-select text-color w-auto border-0  theme-select-box font-14 fw-300  bg-transparent"
                            aria-label="Default select example">
                            <option selected class="d-none">High to low</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>
                <div class="container">
                <div class="box-line bg-2 position-relative rounded-12px mb-2">
                    <span
                        class="bg-3 box-tag-upper pt-1 pb-1 ps-1 pe2 pb-1 d-flex align-item-center justify-content-center">
                        <img style="height: 14px; width: 14px;" src="./../assets/img/jblogo.svg" alt="" />
                        <span class="text-color-7 text-uppercase ms-1 me-2 font-10">BUY</span>
                    </span>
                    <div class="box-line-inner">

                        <h5 class="text-color mb-2 fw-300 font-14 mt-2">Asian Paints</h5>

                        <div class="d-flex  align-items-center justify-content-between ">
                            <div class="">

                                <p class="mb-1 text-color-5 font-12">Qty: 10,500,000</p>
                                <p class="mb-1 font-14 text-dark fw-300">3306.05 <span
                                        class="text-color-9">(-0.19%)</span>
                                </p>
                                <p class="mb-1 text-color-5 font-12">Curr. Value <span
                                        class="font-14 text-dark ms-1">3.30
                                        Cr</span></p>
                            </div>
                            <div class="">
                                <p class="mb-1 font-12 text-color-3 fw-300">G / L <span class="ms-1 text-color-10">+3.2
                                        L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">Today’s G /L <span
                                        class="ms-1 text-color-10">+1.2 L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">XIRR <span class="ms-1 text-color-10">26.25
                                        %</span></p>


                            </div>
                        </div>

                    </div>
                    <div class="bg-3 pt-1 pb-1 text-center">
                        <h5 class="font-14 text-color">View Corporate Action (1)</h5>
                    </div>
                </div>
                <div class="box-line bg-2 position-relative rounded-12px mb-2">

                    <div class="box-line-inner">

                        <h5 class="text-color mb-2 fw-300 font-14 mt-2">Asian Paints</h5>

                        <div class="d-flex  align-items-center justify-content-between ">
                            <div class="">

                                <p class="mb-1 text-color-5 font-12">Qty: 10,500,000</p>
                                <p class="mb-1 font-14 text-dark fw-300">3306.05 <span
                                        class="text-color-9">(-0.19%)</span>
                                </p>
                                <p class="mb-1 text-color-5 font-12">Curr. Value <span
                                        class="font-14 text-dark ms-1">3.30
                                        Cr</span></p>
                            </div>
                            <div class="">
                                <p class="mb-1 font-12 text-color-3 fw-300">G / L <span class="ms-1 text-color-10">+3.2
                                        L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">Today’s G /L <span
                                        class="ms-1 text-color-10">+1.2 L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">XIRR <span class="ms-1 text-color-10">26.25
                                        %</span></p>


                            </div>
                        </div>

                    </div>


                </div>
                <div class="box-line bg-2 position-relative rounded-12px mb-2">

                    <div class="box-line-inner">

                        <h5 class="text-color mb-2 fw-300 font-14 mt-2">Asian Paints</h5>

                        <div class="d-flex  align-items-center justify-content-between ">
                            <div class="">

                                <p class="mb-1 text-color-5 font-12">Qty: 10,500,000</p>
                                <p class="mb-1 font-14 text-dark fw-300">3306.05 <span
                                        class="text-color-9">(-0.19%)</span>
                                </p>
                                <p class="mb-1 text-color-5 font-12">Curr. Value <span
                                        class="font-14 text-dark ms-1">3.30
                                        Cr</span></p>
                            </div>
                            <div class="">
                                <p class="mb-1 font-12 text-color-3 fw-300">G / L <span class="ms-1 text-color-10">+3.2
                                        L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">Today’s G /L <span
                                        class="ms-1 text-color-10">+1.2 L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">XIRR <span class="ms-1 text-color-10">26.25
                                        %</span></p>


                            </div>
                        </div>

                    </div>


                </div>
                <div class="box-line bg-2 position-relative rounded-12px mb-2">
                    <span
                        class="bg-3 box-tag-upper pt-1 pb-1 ps-1 pe2 pb-1 d-flex align-item-center justify-content-center">
                        <img style="height: 14px; width: 14px;" src="./../assets/img/jblogo.svg" alt="" />
                        <span class="text-color-7 text-uppercase ms-1 me-2 font-10">sell</span>
                    </span>
                    <div class="box-line-inner">

                        <h5 class="text-color mb-2 fw-300 font-14 mt-2">Asian Paints</h5>

                        <div class="d-flex  align-items-center justify-content-between ">
                            <div class="">

                                <p class="mb-1 text-color-5 font-12">Qty: 10,500,000</p>
                                <p class="mb-1 font-14 text-dark fw-300">3306.05 <span
                                        class="text-color-9">(-0.19%)</span>
                                </p>
                                <p class="mb-1 text-color-5 font-12">Curr. Value <span
                                        class="font-14 text-dark ms-1">3.30
                                        Cr</span></p>
                            </div>
                            <div class="">
                                <p class="mb-1 font-12 text-color-3 fw-300">G / L <span class="ms-1 text-color-10">+3.2
                                        L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">Today’s G /L <span
                                        class="ms-1 text-color-10">+1.2 L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">XIRR <span class="ms-1 text-color-10">26.25
                                        %</span></p>


                            </div>
                        </div>

                    </div>

                </div>
                <div class="box-line bg-2 position-relative rounded-12px mb-2">

                    <div class="box-line-inner">

                        <h5 class="text-color mb-2 fw-300 font-14 mt-2">Asian Paints</h5>

                        <div class="d-flex  align-items-center justify-content-between ">
                            <div class="">

                                <p class="mb-1 text-color-5 font-12">Qty: 10,500,000</p>
                                <p class="mb-1 font-14 text-dark fw-300">3306.05 <span
                                        class="text-color-9">(-0.19%)</span>
                                </p>
                                <p class="mb-1 text-color-5 font-12">Curr. Value <span
                                        class="font-14 text-dark ms-1">3.30
                                        Cr</span></p>
                            </div>
                            <div class="">
                                <p class="mb-1 font-12 text-color-3 fw-300">G / L <span class="ms-1 text-color-10">+3.2
                                        L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">Today’s G /L <span
                                        class="ms-1 text-color-10">+1.2 L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">XIRR <span class="ms-1 text-color-10">26.25
                                        %</span></p>


                            </div>
                        </div>

                    </div>

                </div>
                <div class="box-line bg-2 position-relative rounded-12px mb-2">
                    <span
                        class="bg-3 box-tag-upper pt-1 pb-1 ps-1 pe2 pb-1 d-flex align-item-center justify-content-center">
                        <img style="height: 14px; width: 14px;" src="./../assets/img/jblogo.svg" alt="" />
                        <span class="text-color-7 text-uppercase ms-1 me-2 font-10">sell</span>
                    </span>
                    <div class="box-line-inner">

                        <h5 class="text-color mb-2 fw-300 font-14 mt-2">Asian Paints</h5>

                        <div class="d-flex  align-items-center justify-content-between ">
                            <div class="">

                                <p class="mb-1 text-color-5 font-12">Qty: 10,500,000</p>
                                <p class="mb-1 font-14 text-dark fw-300">3306.05 <span
                                        class="text-color-9">(-0.19%)</span>
                                </p>
                                <p class="mb-1 text-color-5 font-12">Curr. Value <span
                                        class="font-14 text-dark ms-1">3.30
                                        Cr</span></p>
                            </div>
                            <div class="">
                                <p class="mb-1 font-12 text-color-3 fw-300">G / L <span class="ms-1 text-color-10">+3.2
                                        L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">Today’s G /L <span
                                        class="ms-1 text-color-10">+1.2 L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">XIRR <span class="ms-1 text-color-10">26.25
                                        %</span></p>


                            </div>
                        </div>

                    </div>

                </div>
                <div class="box-line bg-2 position-relative rounded-12px mb-2">
                    <span
                        class="bg-3 box-tag-upper pt-1 pb-1 ps-1 pe2 pb-1 d-flex align-item-center justify-content-center">
                        <img style="height: 14px; width: 14px;" src="./../assets/img/jblogo.svg" alt="" />
                        <span class="text-color-7 text-uppercase ms-1 me-2 font-10">sell</span>
                    </span>
                    <div class="box-line-inner">

                        <h5 class="text-color mb-2 fw-300 font-14 mt-2">Asian Paints</h5>

                        <div class="d-flex  align-items-center justify-content-between ">
                            <div class="">

                                <p class="mb-1 text-color-5 font-12">Qty: 10,500,000</p>
                                <p class="mb-1 font-14 text-dark fw-300">3306.05 <span
                                        class="text-color-9">(-0.19%)</span>
                                </p>
                                <p class="mb-1 text-color-5 font-12">Curr. Value <span
                                        class="font-14 text-dark ms-1">3.30
                                        Cr</span></p>
                            </div>
                            <div class="">
                                <p class="mb-1 font-12 text-color-3 fw-300">G / L <span class="ms-1 text-color-10">+3.2
                                        L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">Today’s G /L <span
                                        class="ms-1 text-color-10">+1.2 L</span></p>
                                <p class="mb-1 font-12 text-color-3 fw-300">XIRR <span class="ms-1 text-color-10">26.25
                                        %</span></p>


                            </div>
                        </div>

                    </div>

                </div>
            </div>
            </div>
        </section>
</template>

<script>
export default ({
    name: 'DirectEquityPage'
})
</script>