<template>
      <!-- back section start -->
        <div class="container mt-3 mb-4 ">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <p class="me-3 text-color font-20">Transactions</p>
                    <p class="mb-0 font-20 fw-normal text-color-6"> Cashflow</p>
                </div>
                <span class="cross-btn">+</span>
            </div>
        </div>
          <section class="tag ">
        <div class="container mt-3 mb-2 border-bottom pb-3">
            <div class="d-flex align-items-center justify-content-between">
                <div class="form-check">
                    <div class="theme-checked">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                    </div>
                    <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                        Show bank transactions
                    </label>
                </div>
                <span class="d-block height-24 rounded me-3 bg-5 text-center"><img src="../assets/img/icons/filter.svg"
                        alt="" /></span>
            </div>
           
        </div>
        <div class="pt-2 pb-2">
        <div class="tages container ">
            <p class="text-color-7 font-14 mb-1">Product</p>
            <div class="">
                <span class="badge rounded-pill bg-5 me-2 mb-3"><span class="d-flex text-color-8 font-11 fw-300"> Direct Equity<span class="cross-small">+</span></span> </span>
                <span class="badge rounded-pill bg-5 me-2 mb-3"><span class="d-flex text-color-8 font-11 fw-300"> Mutual Funds<span class="cross-small">+</span></span> </span>
                <span class="badge rounded-pill bg-5 me-2 mb-3"><span class="d-flex text-color-8 font-11 fw-300"> Debt<span class="cross-small">+</span></span> </span>
                <span class="badge rounded-pill bg-white me-2 mb-3"><span class="d-flex text-color-8 font-11 fw-300"> +2 More</span> </span>



                
            </div>
        </div>
        <div class="tages container">
            <p class="text-color-7 font-14 mb-1">Activity</p>
            <div class="">
                <span class="badge rounded-pill bg-5 me-2 mb-3"><span class="d-flex text-color-8 font-11 fw-300"> MF Application<span class="cross-small">+</span></span> </span>
                <span class="badge rounded-pill bg-5 me-2 mb-3"><span class="d-flex text-color-8 font-11 fw-300"> Purchase<span class="cross-small">+</span></span> </span>
                <span class="badge rounded-pill bg-5 me-2 mb-3"><span class="d-flex text-color-8 font-11 fw-300"> Sell<span class="cross-small">+</span></span> </span>
                <span class="badge rounded-pill bg-white me-2 mb-3"><span class="d-flex text-color-8 font-11 fw-300">+5 More</span> </span>



                
            </div>
        </div>
    </div>
    </section>
         <section class="mb-3">
            <div class="">
            <div class="bg-6 pt-2 pb-2">
                <div class="container">
                    <p class="font-12 text-color-7 pb-1 pt-1 text-uppercase">29 Sep 2021</p>
                </div>
            </div>
            <div class="container pt-3 pb-3 bg-white ">
                <div class="">
                <span class="badge bg-7 rounded-0 fw-normal text-uppercase pb-1">Bank</span>
                <div class="border-bottom pt-4 pb-4 mb-3">
                    <p class="font-14 text-dark">HDFC Bank - 500234875093748</p>
                    <p class="text-color-5 font-12 fw-300">Mutual Fund Application</p>
                    <div class="row m-0   mt-4 ps-m-1">
                        <div class="col-3 border-end pe-1  ">
                            <p class="font-16 mt-0 mb-0 text-dark ">0.00</p>
                            <p class="font-12  mt-0 text-color-5 fw-300 ">Quantity</p>
                        </div>
                        <div class="col-3 border-end pe-1  ">
                            <p class="font-16 mt-0 mb-0 text-dark ">0.00</p>
                            <p class="font-12  mt-0 text-color-5 fw-300 ">Quantity</p>
                        </div>
                        <div class="col-3 ps-3">
                            <p class="font-16 mt-0 mb-0 text-dark ">0.00</p>
                            <p class="font-12  mt-0 text-color-5 fw-300 ">Quantity</p>
                        </div>

                    </div>
                </div>
                </div>
                <div class="mt-4">
                <span class="badge bg-7 rounded-0 fw-normal text-uppercase pb-1">Direct Equity</span>
                <div class=" pt-4 mb-3">
                    <p class="font-14 text-dark">HDFC Bank - 500234875093748</p>
                    <p class="text-color-5 font-12 fw-300">Mutual Fund Application</p>
                    <div class="row m-0   mt-4 ps-m-1">
                        <div class="col-3 border-end pe-1  ">
                            <p class="font-16 mt-0 mb-0 text-dark ">0.00</p>
                            <p class="font-12  mt-0 text-color-5 fw-300 ">Quantity</p>
                        </div>
                        <div class="col-3 border-end pe-1  ">
                            <p class="font-16 mt-0 mb-0 text-dark ">0.00</p>
                            <p class="font-12  mt-0 text-color-5 fw-300 ">Quantity</p>
                        </div>
                        <div class="col-3 ps-3">
                            <p class="font-16 mt-0 mb-0 text-dark ">0.00</p>
                            <p class="font-12  mt-0 text-color-5 fw-300 ">Quantity</p>
                        </div>

                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="">
            <div class="bg-6 pt-2 pb-2">
                <div class="container">
                    <p class="font-12 text-color-7 pb-1 pt-1 text-uppercase">29 Sep 2021</p>
                </div>
            </div>
            <div class="container pt-3 pb-3 bg-white ">
                <div class="">
                <span class="badge bg-7 rounded-0 fw-normal text-uppercase pb-1">Bank</span>
                <div class="border-bottom pt-4 pb-4 mb-3">
                    <p class="font-14 text-dark">HDFC Bank - 500234875093748</p>
                    <p class="text-color-5 font-12 fw-300">Mutual Fund Application</p>
                    <div class="row m-0   mt-4 ps-m-1">
                        <div class="col-3 border-end pe-1  ">
                            <p class="font-16 mt-0 mb-0 text-dark ">0.00</p>
                            <p class="font-12  mt-0 text-color-5 fw-300 ">Quantity</p>
                        </div>
                        <div class="col-3 border-end pe-1  ">
                            <p class="font-16 mt-0 mb-0 text-dark ">0.00</p>
                            <p class="font-12  mt-0 text-color-5 fw-300 ">Quantity</p>
                        </div>
                        <div class="col-3 ps-3">
                            <p class="font-16 mt-0 mb-0 text-dark ">0.00</p>
                            <p class="font-12  mt-0 text-color-5 fw-300 ">Quantity</p>
                        </div>

                    </div>
                </div>
                </div>
              
            </div>
        </div>
        </section> 
</template>

<script>
export default ({
    name: 'TransactionTagPage'
})
</script>