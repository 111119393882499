import {createWebHistory, createRouter} from  'vue-router'

import Home from '../components/Home.vue'
import Portfolio from '../components/Portfolio.vue'
import DirectEquity from '../components/DirectEquity.vue'
import Transactions from '../components/Transactions.vue'
import TransactionFilter from '../components/TransactionFilter.vue'
import TransactionsTags from '../components/TransactionsTags.vue'
import CashFlow from '../components/CashFlow.vue'




const routes =[
    {
        name:'Home',
        path:'/',
        component:Home,
        linkActiveClass: "active",
        linkExactActiveClass: "exact-active",
    },
    {
        name:'Portfolio',
        path:'/portfolio',
        component:Portfolio,
        linkActiveClass: "active",
        linkExactActiveClass: "exact-active",
    },
    {
        name:'DirectEquity',
        path:'/direct-equity',
        component:DirectEquity,
        linkActiveClass: "active",
        linkExactActiveClass: "exact-active",
    },
    {
        name:'Transactions',
        path:'/transactions',
        component:Transactions,
        linkActiveClass: "active",
        linkExactActiveClass: "exact-active",
    },
    {
        name:'TransactionFilter',
        path:'/transaction-filter',
        component:TransactionFilter,
        linkActiveClass: "active",
        linkExactActiveClass: "exact-active",
    },
    {
        name:'TransactionsTags',
        path:'/transactions-tags',
        component:TransactionsTags,
        linkActiveClass: "active",
        linkExactActiveClass: "exact-active",
    },
    {
        name:'CashFlow',
        path:'/cashflow',
        component:CashFlow,
        linkActiveClass: "active",
        linkExactActiveClass: "exact-active",
    }
];

const router = createRouter({
    history:createWebHistory(),
    routes
});

export default router