<template>
    <!-- welcome header start -->

	<div class="container mt-4">
		<div class="row">
			<div class="col-12">
				<div class="span-pill">
					<img src="../assets/img/icons/family.svg">
					Family Aggregate
				</div>
			</div>
			<div class="col-12">
				<h1 class="welcome-text mb-0">Welcome back, Vishal</h1>
			</div>
		</div>
	</div>

<!-- welcome header end -->

<!-- Aggregate section start -->

	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="grph-top-text">
					<h5 class="mb-0">Aggregate</h5>
					<p class="mb-0">
						<span class="light-txt">Portfolio Value</span>
						<span class="dark-txt">95.79 Cr</span>
					</p>
					<div class="row">
						<div class="col-12">
							<div class="aggr-graph progress mt-8">
								<div class="aggr-line first-grph" role="progressbar" style="width: 45%" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
								<div class="aggr-line second-grph" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
								<div class="aggr-line third-grph" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
								<div class="aggr-line fourth-grph" role="progressbar" style="width: 15%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
							</div>
						</div>
						<div class="col-12">
							<div class="count-bar">
								<p><span>0</span></p>
								<p><span>10</span></p>
								<p><span>20</span></p>
								<p><span>30</span></p>
								<p><span>40</span></p>
								<p><span>50</span></p>
								<p><span>60</span></p>
								<p><span>70</span></p>
								<p><span>80</span></p>
								<p><span>90</span></p>
								<p><span>100</span></p>
							</div>
						</div>
						<div class="col-12">
							<div class="aggr-graph progress mt-14">
								<div class="aggr-line first-grph" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
								<div class="aggr-line second-grph" role="progressbar" style="width: 5%" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
								<div class="aggr-line third-grph" role="progressbar" style="width: 8%" aria-valuenow="8" aria-valuemin="0" aria-valuemax="100"></div>
								<div class="aggr-line fourth-grph" role="progressbar" style="width: 3%" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100"></div>
							</div>
						</div>
					</div>
					<p class="mb-0 mt-8">
						<span class="light-txt">Invested Corpus</span>
						<span class="dark-txt">32.75 Cr</span>
					</p>
				</div>
			</div>
		</div>
	</div>

<!-- Aggregate section end -->

<!-- Equity Allocation section start -->

	<div class="container mb-90">
		<div class="row">
			<div class="col-12">
				<div class="equ-all">
					<h5>Equity Allocation</h5>
					<div class="row mt-32">
						<div class="col">
							<div class="false-For-Bottom-Text">
								<div class="mypiechart">	
									<canvas id="myCanvas" width="171" height="171"></canvas>
								</div>
							</div>
						</div>
						<div class="col">
							<ul class="pie-list"> 
								<li> Direct
									<p>3.5 Cr</p>
								</li>
								<li> MF
									<p>6.50 Cr</p>
								</li>
								<li> PMS
									<p>6 Cr</p>
								</li>
								<li> F&O
									<p>2.57 Cr</p>
								</li>
							</ul>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<p class="mb-0">
								<span class="dark-txt d-block">7.7%</span>
								<span class="light-txt">Internationally Listed</span>
							</p>
						</div>
						<div class="col-6">
							<p class="mb-0 text-end">
								<span class="dark-txt d-block">92.3%</span>
								<span class="light-txt">Locally Listed</span>
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="aggr-graph progress mt-8">
								<div class="aggr-line" role="progressbar" style="width: 15%;background: #434B77;" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
								<div class="aggr-line" role="progressbar" style="width: 85%;background: #141E55;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

<!-- Equity Allocation section end -->
<!-- view more section start -->

	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="view-more">
					<h3>View more Pinned items
						<img width="8" height="8" src="@/assets/img/arrowdown.svg">
					</h3>
				</div>
			</div>
			<div class="col-12">
				<div class="day-span-pill mx-auto mt-32">
					TODAY
				</div>
			</div>
		</div>
	</div>

<!-- view more section end -->
<!-- read more section start -->
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="text-box">
					<h1 class="">Edelweiss Finance & Investment Limited (EFIL)</h1>
					<p>EFIL, the issueing NBFC, caters to funding needs of wealth management clients, thereby extending the complete spectrum of offerings through the Wealth Management Platform.</p>
					<div class="tag-text">
						<p>Fixed Income</p>
						<p>Investment Opportunity</p>
					</div>
					<div class="read-more-text d-flex align-items-center justify-content-between">
						<p class="mb-0 mt-0">4:30 PM</p>
						<a href="#">
							<h3>Read more</h3>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
<!-- read more section end -->

<!-- grid video section start -->

	<div class="container mt-20">
		<div class="row">
			<div class="col-12">
				<div class="main-play">
					<div class="play-div">
						<div class="img-sec">
							<img width="40" height="40" src="@/assets/img/nature.jpg" class="">
						</div>
						<div class="img-text">
							<a href="#">
								<img src="@/assets/img/playbtn.svg">
							</a>
							<h3>The Good Cop, Bad Cop Approach</h3>
						</div>
					</div>
					<div class="bottom-text">
						<p>To call financial markets a constant stand To call financial markets a constant stand</p>
						<div class="tag-text">
							<h5>Market Insights</h5>
						</div>
						<div class="read-more-text d-flex align-items-center justify-content-between">
						<p class="mb-0 mt-0">4:20 PM</p>
						<a href="#" class="text-white">
							<h3 class="text-white">Show MORE<img class="ml-8" width="8" height="8" src="@/assets/img/whitearrowdown.svg"></h3>
						</a>
					</div>
					</div>
				</div>
			</div>
		</div>
	</div>

<!-- grid video section end -->
<!-- read more section start -->
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="gov-img">
					<img src="@/assets/img/govern.jpg">
				</div>
				<div class="text-box mt-0 bor-top-rad-0 pt-20">
					<h1 class="">Government Looks to Boost Private Investment, Ease Bottlenecks</h1>
					<p class="mt-40">New Delhi: Amid signs of economics recovery, the government is set to hold... </p>
					<div class="tag-text">
						<p>In the news</p>
					</div>
					<div class="read-more-text d-flex align-items-center justify-content-between">
						<div class="d-flex align-items-center">
							<p class="mb-0 mt-0">1:10 PM</p>
							<div class="d-flex align-items-center brnd-txt">
								<img width="20" height="20" alt="timesofindia" src="@/assets/img/toi.png">
								<p class="mb-0 mt-0">Times of India</p>
							</div>
						</div>
						<a href="#">
							<h3>Read more</h3>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
<!-- read more section end -->
<!-- view more section start -->

	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="day-span-pill mx-auto mt-37">
					11 Nov 2021
				</div>
			</div>
		</div>
	</div>

<!-- view more section end -->
<!-- read more section start -->
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="text-box mt-29">
					<h3 class="">Monthly Report Portfolio</h3>
					<small>Oct. 2021</small>
					<div class="read-more-text d-flex align-items-center mt-24">
						<p class="mb-0 mt-0">Portfolio Level</p>
						<p class="mb-0 mt-0 opacity-1 ml-6">Aggregate Family</p>
					</div>
					<div class="tag-text">
						<p>Portfolio</p>
						<p>Reports</p>
					</div>
					<div class="read-more-text d-flex align-items-center justify-content-between">
						<p class="mb-0 mt-0">11:00 AM</p>
					</div>
				</div>
			</div>
		</div>
	</div>
<!-- read more section end -->
<!-- read more section start -->
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="text-box">
					<h1 class="">A Live Webinar to Explain Fundamentals of Crypto Currency Investments</h1>
					<h4>By Tarang Gupta</h4>
					<div class="post-info">
						<div class="d-flex align-items-center post-table">
							<img width="20" height="20" src="@/assets/img/clock.svg">
							<p class="mt-0">30th November, 2021 - 4:30 PM to 6:00 PM</p>
						</div>
						<div class="d-flex align-items-center post-table">
							<img width="20" height="20" src="@/assets/img/loacation.svg">
							<p class="mt-0">30th November, 2021 - 4:30 PM to 6:00 PM</p>
						</div>
					</div>
					<div class="tag-text">
						<p>Expert Talk</p>
					</div>
					<div class="read-more-text d-flex align-items-center justify-content-between">
						<p class="mb-0 mt-0">10:30 AM</p>
						<a href="#">
							<h3>Send me invite</h3>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
<!-- read more section end -->
<!-- read more section start -->
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="text-box bg-gry">
					<h1 class="">Motilal Oswal Nasdaq 100 ETF</h1>
					<div class="report-info">
						<div class="d-flex align-items-center">
							<img width="25" height="30" src="@/assets/img/report.svg">
							<div class="report-text">
								<h2>JB Research Report</h2>
								<p class="mt-0">Published: 13th Oct 2021</p>
							</div>
						</div>
					</div>
					<div class="tag-text">
						<p>Equity MF</p>
						<p>JB Research</p>
					</div>
					<div class="read-more-text d-flex align-items-center justify-content-between">
						<p class="mb-0 mt-0">9:30 AM</p>
						<a href="#">
							<h3>Read More</h3>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
<!-- read more section end -->

<!-- read more section start -->
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="gov-img">
					<img src="@/assets/img/govern.jpg">
				</div>
				<div class="text-box mt-0 bor-top-rad-0 pt-20">
					<h1 class="">Government Looks to Boost Private Investment, Ease Bottlenecks</h1>
					<p class="mt-40">New Delhi: Amid signs of economics recovery, the government is set to hold... </p>
					<div class="tag-text">
						<p>In the news</p>
					</div>
					<div class="read-more-text d-flex align-items-center justify-content-between">
						<div class="d-flex align-items-center">
							<p class="mb-0 mt-0">1:10 PM</p>
							<div class="d-flex align-items-center brnd-txt">
								<img width="20" height="20" alt="timesofindia" src="@/assets/img/toi.png">
								<p class="mb-0 mt-0">Times of India</p>
							</div>
						</div>
						<a href="#">
							<h3>Read more</h3>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
<!-- read more section end -->
</template>

<script>
    export default {
        name:'HomePage',
    }
</script>