<template>
<!-- back section start -->
<div class="container mt-3 mb-4">

    <div class="d-flex align-items-center justify-content-between">
        <p class="me-3 text-dark font-20">Filters</p>
        <div class="d-flex align-items-center">
            <p class="text-color-1 me-3 font-12 fw-bold mt-1 mb-0">RESET</p>
            <p class="text-color me-3 mt-1 font-12 fw-bold mb-0 ">APPLY</p>
            <span class="cross-btn">+</span>
        </div>
    </div>

</div>
<section class="theme-accordian">
    <div class="accordion" id="accordionExample">
        <div class="accordion-item border-0">
            <h2 class="accordion-header" id="headingOne">
                <div class="d-flex p-3">
                    <div class="form-check theme-checked pe-2">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Product
                        </label>
                    </div>
                    <button class="accordion-button p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                    </button>
                </div>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Activity Type
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Broker NSE - EQUITY
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            DP Charges
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Dividends
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Interest Received on Bonds/G Ses
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Misc Receipts
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Mutual Fund Application
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Mutual Fund Redemption Receiv...
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Purchase
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Sell
                        </label>
                    </div>

                </div>
            </div>
        </div>
        <div class="accordion-item border-0">
            <h2 class="accordion-header" id="headingOne">
                <div class="d-flex p-3">
                    <div class="form-check theme-checked pe-2">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Show bank transactions
                        </label>
                    </div>
                    <button class="accordion-button p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">

                    </button>
                </div>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Show bank transactions
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Show bank transactions
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Show bank transactions
                        </label>
                    </div>
                    <div class="form-check theme-checked pe-2 mb-3">

                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>

                        <label class="form-check-label font-12 text-color-5 fw-300 ms-1" for="flexCheckChecked">
                            Show bank transactions
                        </label>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="bg-6 pt-2 pb-2">
            <div class="container">
                <p class="font-12 text-color-7 pb-1 pt-1 text-capitalized">Date Range Selector</p>
            </div>
        </div>
        <div class="container pt-3 pb-3 bg-white ">
            <div class="row">
                <div class="col-6">
                    <form class="theme-calendar">
                        <label for="date" class="col-1 col-form-label font-12 color-5 fw-300">From</label>

                        <div class="input-group date" id="datepicker">
                            <input type="text" class="form-control border-end-0" id="date" placeholder="DD/MM/YYYY" />
                            <span class="input-group-append">
                                <span class="input-group-text rounded-0 bg-white d-block">
                                    <img src="img/icons/date-icon.svg" alt="" />
                                </span>
                            </span>
                        </div>

                    </form>
                </div>
                <div class="col-6">
                    <form class="theme-calendar">
                        <label for="date" class="col-1 col-form-label font-12 color-5 fw-300">To</label>

                        <div class="input-group date" id="datepicker">
                            <input type="text" class="form-control border-end-0" id="date" placeholder="DD/MM/YYYY" />
                            <span class="input-group-append">
                                <span class="input-group-text rounded-0 bg-white d-block">
                                    <img src="img/icons/date-icon.svg" alt="" />
                                </span>
                            </span>
                        </div>

                    </form>
                </div>
            </div>
        </div>

    </div>

</section>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'TransactionFilterPage',
    mounted: function () {
        this.splash()
    },
    methods: {
        splash() {
            $(".accordion-button").click(function () {
                $(".accordion-item").removeClass("bg-5");

                $(this).parents(".accordion-item").toggleClass("bg-5");
            });
        }
    },
}
</script>
