import { createApp } from 'vue'
import App from './App.vue'
import "./assets/css/style.css"



// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'

// Make BootstrapVue available throughout your project

import router from './routes/routes'

// import jQuery from "jquery";
window.$ = window.jQuery = require('jquery')

createApp(App).use(router).mount('#app')

import 'bootstrap/dist/js/bootstrap.js'
